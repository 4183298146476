@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

.rocket {
  height: 80vh;
  z-index: 2;
  display: flex;
  transition: display 2s;
  /* animation: moveUpDown 1.5s infinite; */
}

.hidden {
  display: none;
  transition: display 2s;

}
