body {
  margin: 0;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.scroll-wrap {
  position: relative;
}
.scroll-wrap li {
  height: 50vh;
}
.movie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #111;
}
.movie .bg {
  width: 100vw;
  height: 100vh;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.movie .bg img {
  /*   height:100vh; */
  transition: 1s;
}
.movie.step-2 .bg img {
  transform: scale(0.9);
}
.movie.step-3 .bg img {
  transform: scale(0.8);
}
.story,
.actor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  opacity: 0;
  transition: 1s;
}
.story:before,
.actor:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: bottom;
}
.story-content,
.actor ul {
  display: inline-block;
  text-align: left;
  margin-bottom: 10%;
  width: 600px;
  transform: scale(2);
  transition: 1s;
}
.actor ul {
  margin-bottom: 20%;
}
.actor ul li {
  display: inline-block;
  vertical-align: top;
}
.actor ul li + li {
  margin-left: 40px;
}
.movie.step-2 .story,
.movie.step-3 .story {
  opacity: 1;
}
.movie.step-2 .story-content {
  transform: scale(1);
}
.movie.step-3 .story-content {
  transform: scale(0.5);
}
.movie.step-3 .actor {
  opacity: 1;
}
.movie.step-3 .actor ul {
  transform: scale(1);
}

.hide {
  -webkit-animation: fadeinout 0.3s linear forwards;
  animation: fadeinout 0.3s linear forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

.show {
  -webkit-animation: display 0.5s linear forwards;
  animation: display 0.5s linear forwards;
}

@-webkit-keyframes display {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes display {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.zoomOut {
  -webkit-animation: fadeinout 0.4s linear forwards;
  animation: fadeinout 0.4s linear forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.zoomIn {
  -webkit-animation: display 0.5s linear forwards;
  animation: display 0.5s linear forwards;
}

@-webkit-keyframes display {
  0% {
    opacity: 0.2;
    transform: scale(0.5);
  }
  50% {
    opacity: 0.6;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);

  }
}

@keyframes display {
  0% {
    opacity: 0.2;
    transform: scale(0.5);

  }
  50% {
    opacity: 0.6;
    transform: scale(0.8);

  }
  100% {
    opacity: 1;
    transform: scale(1);

  }
}



.slide-in {
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
      opacity: 0;
      transform: translateX(-100%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.bounce-out {
  animation: bounceOut 0.5s forwards;
}

@keyframes bounceOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
      transform: translateY(-20px);
  }
}
